@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

.p-about{
	&__sc1{
		padding: 147px 0 160px;
		box-sizing: border-box;

		@include m.sp{
			padding: 40px 0 40px;
		}

		.c-title1{
			margin-bottom: 100px;

			@include m.sp{
				margin-bottom: 30px;
			}
		}
	}

	&__sc2{
		padding-bottom: 140px;

		@include m.sp{
			padding-bottom: 40px;
		}

		.c-block4{
			margin-bottom: 66px;

			@include m.sp{
				margin-bottom: 30px;
			}
		}
	}
}
