//-------------------------------------------
// base width
//-------------------------------------------
$base-width: 1400px;

//-------------------------------------------
// Common Color
//-------------------------------------------
$color1: #010101;
$color2: #214082;
$color3: #E9452D;
$color4: #D8280E;
$color5: #F2F2F2;

//-------------------------------------------
// z-index
//-------------------------------------------
$index-header: 10;
