@use '../settings/mixin' as m;
@use '../settings/vars' as v;
//-------------------------------------------
// u-...
//-------------------------------------------
.u-bg1 {
	background-color: v.$color5;
}

//-------------------------------------------
// Utility-first
//-------------------------------------------
.pc {
	@include m.sp {
		display: none !important;
	}
}

.sp {
	@include m.pc {
		display: none !important;
	}
}

.text {
	&-center {
		text-align: center;
	}

	&-left {
		text-align: left;
	}

	&-right {
		text-align: right;
	}
}

.flex {
	display: flex;

	&-wrap {
		flex-wrap: wrap;
	}

	&-row {
		flex-direction: row;

		&.reverse {
			flex-direction: row-reverse;
		}
	}

	&-column {
		flex-direction: column;

		&.reverse {
			flex-direction: column-reverse;
		}
	}

	&.justify {
		&-start {
			justify-content: flex-start;
		}

		&-center {
			justify-content: center;
		}

		&-end {
			justify-content: flex-end;
		}

		&-between {
			justify-content: space-between;
		}
	}

	&.align {
		&-start {
			align-items: flex-start;
		}

		&-center {
			align-items: center;
		}

		&-end {
			align-items: flex-end;
		}

		&-stretch {
			align-items: stretch;
		}
	}
}
