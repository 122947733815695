@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

//------------------------------------------------------------
//c-btn1
//------------------------------------------------------------
.c-btn1{
	background-color: v.$color3;
	color: #fff;
	font-size: 1.8rem;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	padding: 14px 10px;
	position: relative;
	border-radius: 60px;
	width: 200px;
	$root: &;

	@include m.sp{
		font-size: 1.6rem;
	}

	&::after{
		position: relative;
		content: '';
		display: inline-block;
		width: 9px;
		height: 9px;
		background: url(../img/common/icon_arrow.svg) no-repeat center right;
		background-size: 9px 9px;
		margin-right: -9px;
		right: -8px;
	}

	@include m.hover{
		background-color: v.$color4;
		opacity: 1;
	}

	&--youtube{
		padding-left: 40px;

		&::after{
			display: none;
		}

		&::before{
			position: absolute;
			content: '';
			width: 25px;
			height: 25px;
			background: url(../img/common/icon_youtube.svg) no-repeat center right;
			background-size: 25px 25px;
			left: 30px;

			@include m.sp{
				width: 22px;
				height: 22px;
				background: url(../img/common/icon_youtube.svg) no-repeat center right;
				background-size: 22px 22px;
				left: 37px;
			}
		}
	}
}

//------------------------------------------------------------
//c-btn2
//------------------------------------------------------------
.c-btn2{
	color: #000;
	font-size: 1.8rem;
	font-weight: 600;
	line-height: 1;
	position: relative;
	display: flex;
	justify-content: center;

	&::after{
		position: relative;
		content: '';
		display: inline-block;
		width: 18px;
		height: 18px;
		background: url(../img/common/icon_arrow_red.svg) no-repeat center;
		background-size: 18px 18px;
		margin-left: 15px;
	}

	@include m.hover{
		color: v.$color3;
		opacity: 1;
	}
}
