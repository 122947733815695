@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

.c-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: v.$index-header;
  $root: &;

  @include m.sp {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__top {
    height: 60px;
    background-color: v.$color2;
    @include m.sp {
      display: none;
    }

    #{$root}__inner {
      display: flex;
      align-items: center;

      @include m.sp {
        flex-direction: column;
        padding: 40px 15px 40px;
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-right: 24px;

    @include m.sp {
      margin-top: 20px;
      margin-right: 0;
    }

    a {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__infor {
    margin-right: auto;
    @include m.sp {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 0;
    }
  }

  &__tel,
  &__mail {
    position: relative;
    padding-left: 28px;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }

  &__tel {
    &::before {
      background: url(../img/common/icon_phone_w.svg) no-repeat center;
      width: 14px;
      height: 14px;
      background-size: 14px 14px;
    }
  }

  &__mail {
    margin-left: 50px;

    @include m.sp {
      margin-left: 0;
      margin-top: 20px;
    }

    &::before {
      background: url(../img/common/icon_mail_w.svg) no-repeat center;
      width: 14px;
      height: 10px;
      background-size: 14px 10px;
    }
  }

  &__wrap {
    position: relative;
    background-color: #ffffff;
    height: 80px;

    @include m.sp {
      order: 1;
      box-sizing: border-box;
    }
  }

  &__inner {
    display: flex;
    align-items: stretch;
    max-width: v.$base-width + 30px;
    margin: 0 auto;
    height: 100%;
    padding: 0 15px;
    box-sizing: border-box;

    @include m.sp {
      align-items: center;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: auto;
    z-index: 100;
    @include m.max-w(v.$base-width) {
      // padding-left: 15px;
    }
  }

  &__gnav {
    .c-navi1 {
      height: 100%;
      @include m.sp {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }

  &__navtoggle {
    display: none;
    cursor: pointer;

    @include m.sp {
      width: 35px;
      height: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: all 0.3s ease-out;
    }

    &.is-active {
      span {
        transition: all 0.3s ease-in-out;

        &:nth-child(1) {
          top: 0;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          width: 0;
          opacity: 0;
        }

        &:nth-child(3) {
          bottom: 0;
          transform: rotate(-45deg);
          width: 35px;
        }
      }
    }

    span {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      display: block;
      background-color: v.$color1;
      width: 35px;
      height: 2px;
      z-index: 99;

      &:nth-child(1) {
        top: -18px;
      }

      &:nth-child(3) {
        bottom: -18px;
        width: 20px;
        left: 0;
        right: initial;
      }
    }
  }

  &__navwrap {
    position: relative;
    height: 100%;

    @include m.sp {
      width: 0;
      opacity: 0;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      background-color: #fff;
      transition: all 0.3s ease-out;
      z-index: 98;
      display: flex;
      flex-direction: column;

      &.is-open {
        opacity: 1;
        width: 100%;
      }
    }
  }

  &__navinfo {
    display: none;
    @include m.sp {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      background-color: #214082;
      color: #ffffff;
      padding: 40px 20px;
    }
  }

  &__lang {
    @include m.sp {
      position: absolute;
      top: 10px;
      right: 20px;
    }
  }
}

.c-navi1 {
  display: flex;

  &__list {
    display: flex;

    @include m.sp {
      flex-direction: column;
      width: 100%;
      padding-top: 80px;
    }

    & > .menu-item {
      position: relative;
      flex: 1 1 auto;

      @include m.sp {
        flex: none;
      }
      @include m.hover {
        & > a {
          opacity: 1;
          background-color: v.$color3;
          color: #ffffff;
        }

        & > .sub-menu {
          visibility: visible;
          transform: scale(1);
          opacity: 1;
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 1px;
        background-color: #ffffff;
      }

      & > a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 10px 1vw;
        font-size: 1.5rem;
        line-height: 100%;
        font-weight: bold;
        text-transform: uppercase;

        @include m.sp {
          height: 60px;
        }
      }

      & > .sub-menu {
        display: block;
        background-color: #ffffff;
        position: absolute;
        top: 100%;
        left: 0;
        transform: scale(0);
        transform-origin: top left;
        min-width: calc(100% - 1px);
        visibility: hidden;
        opacity: 0;
        transition: 0.3s;
        @include m.shadow;
        @include m.sp {
          display: none;
          position: relative;
          opacity: 1;
          visibility: visible;
          transform: scale(1);
          transition: none;
          top: 0;
        }

        .menu-item > a {
          display: block;
          text-align: left;
          padding: 15px 1vw;
          font-size: 1.5rem;
          line-height: 1.2;
          font-weight: 500;
          text-transform: uppercase;
          white-space: nowrap;
          @include m.hover {
            opacity: 1;
            background-color: v.$color3;
            color: #ffffff;
          }
          @include m.sp {
            white-space: normal;
            padding: 15px 30px;
          }
        }
      }
    }

    .current-menu-item > a,
    .current-menu-parent > a {
      background-color: v.$color3;
      color: #ffffff;
      opacity: 1;
    }

    .sub-menu {
      display: none;
    }
  }

  .menu-item-has-children > a > &__text1 {
    position: relative;

    &:after {
      position: relative;
      content: "+";
      display: inline-block;
      margin-left: -8px;
      right: -12px;
      top: -1px;
    }
  }
}

.c-lang {
  position: relative;
  cursor: pointer;

  &__curr {
    position: relative;
    padding-right: 20px;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      width: 8px;
      height: 8px;
      border-left: 2px solid #fff;
      border-top: 2px solid #fff;
      transform: translateY(-50%) rotate(225deg);
      margin-top: -2px;
    }
  }

  &__list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
    display: none;
  }

  &__item {
    width: 25px;
    height: auto;
    display: block;
    user-select: none;
  }

  &__link {
    display: block;
    opacity: 1;
    text-decoration: none;
    @include m.hover {
      opacity: 1;
    }
  }
}

.langBar {
  margin-left: 40px;
  position: relative;
  padding-left: 20px;

  @include m.sp {
    margin-left: 0;
    margin-top: 20px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 5px;
    width: 8px;
    height: 8px;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    transform: rotate(225deg);

    @include m.sp {
      top: 3px;
    }
  }

  img {
    height: 15px;
    line-height: 1;
  }

  &.opened {
    &::before {
      top: 8px;
      transform: rotate(45deg);
    }
  }

  .allLang {
    position: absolute;
    top: 25px;
    left: 15px;
    display: none;
  }

  #curr_lang {
    line-height: 1;
  }
}
