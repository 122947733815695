@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

//------------------------------------------------------------
//c-text1
//------------------------------------------------------------*/
.c-text1{
	font-weight: 500;
	text-align: center;
}
