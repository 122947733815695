@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

.p-contact {
	&__sc{
		padding: 100px 0 76px;
		box-sizing: border-box;

		@include m.sp{
			padding: 40px 0 40px;
		}

		.c-contact{
			margin-bottom: 80px;

			@include m.sp{
				margin-bottom: 40px;
			}
		}
	}
}
