@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

//------------------------------------------------------------
//c-title1
//------------------------------------------------------------*/
.c-title1 {
  position: relative;
  display: flex;
  justify-content: center;

  &__big {
    font-size: 12rem;
    font-weight: 800;
    text-transform: uppercase;
    -webkit-text-stroke: 1px rgba(33, 64, 130, 0.4);
    color: transparent;
    line-height: 1;
	text-align: center;

    @include m.max-w(1200px) {
      font-size: 10rem;
    }

    @include m.sp {
      font-size: 6rem;
      letter-spacing: -0.1rem;
    }
  }

  &__sm {
    font-size: 6rem;
    position: absolute;
    bottom: 0;
    font-weight: 800;
    text-transform: uppercase;
    color: v.$color2;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1.25;
    width: 100%;
    text-align: center;

    @include m.max-w(1200px) {
      font-size: 3.6rem;
    }

    @include m.sp {
      font-size: 3rem;
    }
  }
}

//------------------------------------------------------------
//c-title2
//------------------------------------------------------------*/
.c-title2 {
  font-size: 2.8rem;
  line-height: 1.2;
  font-weight: 800;
  position: relative;
  padding-left: 38px;
  text-transform: uppercase;

  @include m.sp {
    font-size: 1.8rem;
    padding-left: 24px;
  }

  &::before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-left: 24px solid #E9452D;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right-color: transparent;

    @include m.sp {
      border-left-width: 16px;
      border-top-width: 8px;
      border-bottom-width: 8px;
    }
  }
}

//------------------------------------------------------------
//c-title3
//------------------------------------------------------------*/
.c-title3 {
  font-size: 4.2rem;
  font-weight: 800;
  line-height: 1.4;

  @include m.sp {
    font-size: 2.4rem;
    line-height: 1.3;
  }
}
