@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

.p-media{
	&__sc{
		padding: 100px 0 100px;
		box-sizing: border-box;

		@include m.sp{
			padding: 40px 0 40px;
		}

		.c-imgtxt3{
			margin-bottom: 80px;

			@include m.sp{
				margin-bottom: 30px;
			}
		}
	}

	&__detail{
		&__sc{
			.c-social{
				margin-bottom: 30px;

				@include m.sp{
					margin-bottom: 20px;
				}
			}
		}
	}
}
