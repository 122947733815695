@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

//------------------------------------------------------------
//c-mv
//------------------------------------------------------------
.c-mv {
  &__wrap {
    position: relative;
    @include m.sp {
      padding: 0 20px;
    }
  }

  &__img {
    position: relative;
    height: 400px;

    @include m.sp {
      height: 300px;
    }

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: calc(100% - 62px);
      top: 62px;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      opacity: 0.7;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__ttl {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 7rem;
    font-weight: 800;
    line-height: 1.2;
    color: #fff;
    text-align: center;
    text-transform: uppercase;

    @include m.sp {
      font-size: 3.5rem;
    }
  }
}

//------------------------------------------------------------
//c-sidebar
//------------------------------------------------------------
.c-sidebar {
  $root: &;

  &__wrap {
    border: 1px solid #C5C5C5;
    border-radius: 20px;
    padding: 20px 0;
    box-sizing: border-box;
  }

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid #C5C5C5;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }

  &__pd {
    padding: 0 0 0 20px;

    &.is-open {
      #{$root}__arrow {
        &::after {
          opacity: 0;
		  height: 0;
        }

        &::before {
          background-color: v.$color3;
        }
      }

      #{$root}__icon {
        filter: brightness(100%);
      }

      #{$root}__sub {
        display: block;
      }

      #{$root}__ttl {
        color: v.$color3;
      }
    }
  }

  &__icon {
    width: 48px;
    flex-shrink: 0;
    margin-right: 25px;
    filter: brightness(0%);
    position: relative;
    top: 10px;
    text-align: center;

    @include m.sp {
      width: 30px !important;
      top: 0;
      margin-right: 20px;
    }
  }

  &__ttl {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__link {
    font-size: 1.8rem;
    line-height: 3.2rem;
    font-weight: 800;
    width: 88%;
    flex-shrink: 0;
    position: relative;
    display: flex;
    text-transform: uppercase;

    @include m.sp {
      font-size: 1.6rem;
      line-height: 2.4rem;
      align-items: center;
    }
  }

  &__arrow {
    user-select: none;

    &.js-accordion {
      width: 40px;
      height: 40px;
      position: relative;
      cursor: pointer;
      flex-shrink: 0;
      transition: all 0.3s ease-in-out;

      &::before,
      &::after {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        margin: auto;
        left: 50%;
        transform: translateX(-50%);
        background-color: v.$color1;
      }

      &::before {
        width: 12px;
        height: 2px;
      }

      &::after {
        height: 12px;
        width: 2px;
      }
    }
  }

  &__subttl {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 4rem;

    @include m.sp {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  &__sub {
    padding-top: 15px;
    padding-left: 73px;
    display: none;

    @include m.sp {
      padding-left: 50px;
    }
  }

  &__txt {
    @include m.sp {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .c-title2 {
    margin-bottom: 30px;

    @include m.sp {
      margin-bottom: 20px;
    }
  }
}

//------------------------------------------------------------
//c-category
//------------------------------------------------------------
.c-category {
  display: flex;
  $root: &;

  &__item {
    text-align: center;
    @include m.sp {
      width: 50%;
    }

    &.is-all {
      flex: 1 0 140px;
    }

    &.is-active {
      #{$root}__txt {
        opacity: 1;
        background-color: v.$color3;
        color: #fff;
        border-top-color: v.$color3;
        border-bottom-color: v.$color3;
      }
    }

    @include m.pc {
      &:not(:first-child) {
        border-left: 1px solid #C5C5C5;

      }

      &:first-child {
        #{$root}__txt {
          border-left: 1px solid #C5C5C5;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
      }

      &:last-child {
        #{$root}__txt {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          border-right: 1px solid #C5C5C5;
        }
      }

      &.is-active {
        #{$root}__txt {
          opacity: 1;
          background-color: v.$color3;
          color: #fff;
          border-top-color: v.$color3;
          border-bottom-color: v.$color3;
        }

        &:first-child {
          &.is-active {
            #{$root}__txt {
              border-left-color: v.$color3;
            }
          }
        }

        &:last-child {
          &.is-active {
            #{$root}__txt {
              border-right-color: v.$color3;
            }
          }
        }
      }

      @include m.hover {
        #{$root}__txt {
          opacity: 1;
          background-color: v.$color3;
          color: #fff;
          border-top-color: v.$color3;
          border-bottom-color: v.$color3;
        }

        &:first-child {
          #{$root}__txt {
            border-left-color: v.$color3;
          }
        }

        &:last-child {
          #{$root}__txt {
            border-right-color: v.$color3;
          }
        }
      }
    }
  }

  &__txt {
    padding: 12px;
    box-sizing: border-box;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 100%;
    border-top: 1px solid #c5c5c5;
    border-bottom: 1px solid #c5c5c5;
  }
}

//------------------------------------------------------------
//.c-select
//------------------------------------------------------------
.c-select {
  border: 0;
  width: 100%;
  height: 60px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.5;
  color: v.$color3;
  cursor: pointer;
  background: #fff url(../img/common/icon_arrow_down.svg) no-repeat center right 13px/13px;
  padding-left: 30px;
  padding-right: 30px;
  appearance: none;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  border: 1px solid #C5C5C5
}

//------------------------------------------------------------
//.c-related
//------------------------------------------------------------
.c-related {
  .c-title2 {
    margin-bottom: 30px;

    @include m.sp {
      margin-bottom: 20px;
    }
  }

  .c-list4 {
    grid-template-columns: 1fr;
    grid-gap: 0;
    row-gap: 40px;

    @include m.sp {
      row-gap: 20px;
    }
  }
}

//------------------------------------------------------------
//.c-wrapPadding
//------------------------------------------------------------
.c-wrapPadding {
  padding: 100px 0 100px;
  box-sizing: border-box;

  @include m.sp {
    padding: 40px 0 40px;
  }

  .c-title3 {
    margin-bottom: 30px;

    @include m.sp {
      margin-bottom: 20px;
    }
  }
}

//------------------------------------------------------------
//.c-social
//------------------------------------------------------------
.c-social {
  display: flex;
  align-items: center;
  $root: &;

  &__date {
    line-height: 1;
  }

  &__wrap {
    display: flex;
    margin-left: auto;
  }

  &__btn {
    background-color: #1877F2;
    line-height: 1;
    padding: 8px 17px;
    box-sizing: border-box;
    color: #fff;
    border-radius: 5px;
    font-size: 1.4rem;
    font-weight: 600;

    @include m.sp {
      font-size: 1.2rem;
    }

    &:not(:first-child) {
      margin-left: 10px;
    }

    > span {
      margin-left: 5px;
    }

    &.like {
      display: flex;
      align-items: center;
      padding: 8px 13px;

      &::before {
        position: relative;
        content: '';
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 13px;
        height: 11px;
        background: url(../img/common/icon_heart.svg) no-repeat center;
        background-size: 13px 11px;
        margin-right: 6px;
      }
    }
  }
}

//------------------------------------------------------------
//.c-contact
//------------------------------------------------------------
.c-contact {
  display: flex;
  flex-wrap: wrap;
  $root: &;

  &__map {
	flex-basis: 0;
    flex-grow: 1;

	@include m.sp{
		flex-basis: auto;
		width: 100%;
	}

    iframe {
      width: 100%;
      height: 100%;
    }

    @include m.sp {
      width: 100%;

      iframe {
        height: 300px;
      }
    }
  }

  &__infor {
	width: 473px;
	flex-shrink: 0;
    background-color: v.$color5;
    padding: 60px 50px 120px;
    box-sizing: border-box;

    @include m.sp {
      width: 100%;
      padding: 30px;
    }
  }

  &__ttl {
    font-size: 5rem;
    line-height: 1.2;
    color: v.$color3;
    font-weight: 800;
    margin-bottom: 40px;

    @include m.sp {
      font-size: 2.2rem;
      margin-bottom: 15px;
    }
  }

  &__txt {
    font-size: 2.2rem;
    line-height: 3.4rem;
    font-weight: 500;
    position: relative;
    padding-left: 30px;

    @include m.sp {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }

    &::before {
      position: absolute;
      content: '';
      top: 8px;
      left: 0;
      width: 15px;
      height: 18px;
      background: url(../img/common/icon_addess.svg) no-repeat center;
      background-size: 15px 18px;

      @include m.sp {
        top: 5px;
      }
    }

    &.phone {
      &::before {
        top: 11px;
        width: 16px;
        height: 15px;
        background: url(../img/common/icon_phone.svg) no-repeat center;
        background-size: 16px 15px;

        @include m.sp {
          top: 5px;
        }
      }
    }

    &.mail {
      &::before {
        top: 13px;
        width: 16px;
        height: 12px;
        background: url(../img/common/icon_mail.svg) no-repeat center;
        background-size: 16px 12px;

        @include m.sp {
          top: 7px;
        }
      }
    }

    + #{$root}__txt {
      margin-top: 13px;
    }

    &:nth-of-type(2) {
      margin-top: 45px;

      @include m.sp {
        margin-top: 13px;
      }
    }
  }
}

//------------------------------------------------------------
//.c-countdown
//------------------------------------------------------------
.c-countdown {
  $root: &;
  padding: 90px 0 117px;
  box-sizing: border-box;

  @include m.sp {
    padding: 40px 0 40px;
  }

  .c-title1 {
    margin-bottom: 60px;

    @include m.sp {
      margin-bottom: 30px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 30px;
    padding: 0 30px;

    @include m.min-w(1600px) {
      grid-template-columns: repeat(5, auto);
    }

    @include m.sp {
      grid-template-columns: 1fr;
      grid-gap: 30px 0;
    }
  }

  &__item {
    text-align: center;
  }

  &__couter {
    font-size: 10rem;
    font-weight: 800;
    line-height: 1;
    -webkit-text-stroke: 1px rgba(33, 64, 130, 0.4);
    color: transparent;
    position: relative;

    @include m.sp {
      font-size: 6rem;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;

    @include m.hover {
      #{$root}__num {
        font-size: 10rem;
        transition: all 0.3s ease-out;
        line-height: 1;
        white-space: nowrap;
      }
    }
  }

  &__num {
    font-size: 5rem;
    color: v.$color3;
    font-weight: 900;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1.25;
    width: 100%;

    @include m.sp {
      font-size: 3rem;
    }
  }

  &__txt {
    font-size: 2.2rem;
    font-weight: 800;
    line-height: 3.4rem;
    text-transform: uppercase;
    margin-top: 6px;

    @include m.sp {
      font-size: 1.8rem;
    }

    .u-blue {
      color: v.$color2;
    }
  }
}

//------------------------------------------------------------
//.c-chart
//------------------------------------------------------------
.c-chart {
  $root: &;
  padding: 100px 0 100px;
  box-sizing: border-box;

  @include m.sp {
    padding: 40px 0 40px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    width: 80%;
    margin: 0 auto;

    @include m.sp {
      flex-wrap: wrap;
      width: 100%;
    }
  }

  &__map {
    width: 445px;
    flex-shrink: 0;
    margin-right: 90px;

    @include m.sp {
      width: 100%;
      margin: 0 auto 30px;
    }

    canvas {
      aspect-ratio: auto 445 / 445;
    }
  }

  &__ct {
    flex-basis: 0;
    flex-grow: 1;

    @include m.sp {
      flex-basis: auto;
      width: 100%;
    }
  }

  &__ttl {
    font-size: 5rem;
    font-weight: 800;
    line-height: 1.2;
    color: v.$color2;
    margin-bottom: 30px;

    @include m.sp {
      font-size: 2.4rem;
      margin-bottom: 15px;
    }
  }

  &__des {
    font-size: 2.6rem;
    line-height: 3.6rem;
    font-weight: 500;
    margin-bottom: 30px;

    @include m.sp {
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin-bottom: 15px;
    }
  }

  &__txt {
    padding-left: 72px;
    position: relative;
    font-size: 2.6rem;
    line-height: 1.3;
    font-weight: 500;

    @include m.sp {
      font-size: 1.8rem;
      line-height: 2.4rem;
      padding-left: 40px;
    }


    & + #{$root}__txt {
      margin-top: 37px;

      @include m.sp {
        margin-top: 15px;
      }
    }
  }

  &__color {
    position: absolute;
    content: '';
    width: 35px;
    height: 35px;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: #000000;

    @include m.sp {
      width: 25px;
      height: 25px;
    }
  }
}

.c-detail1 {
  &__img {
    position: relative;
    overflow: hidden;
    width: 100%;

    &:after {
      content: '';
      display: block;
      padding-top: calc(492 * 100% / 808);
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__infor {
    display: flex;
    border: 1px solid #E7E7E7;
    border-top: 0;
  }

  &__left {
    width: 100%;
    padding: 20px 40px;

	@include m.sp{
		padding: 15px;
	}
  }

  &__logo {
    flex-shrink: 0;
    max-width: 255px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #E7E7E7;
    @include m.sp {
      max-width: 143px;
    }

    img {
      max-width: 100%;
    }
  }

  &__name {
    color: #010101;
    font-size: 2.2rem;
    line-height: 1.4;
    text-transform: uppercase;
    display: block;
    font-weight: 400;
    @include m.sp {
      font-size: 1.2rem;
    }
  }

  &__txt {
    color: #000000;
    font-size: 3rem;
    line-height: 1.4;
    font-weight: bold;
    @include m.sp {
      font-size: 1.6rem;
    }
  }
}
