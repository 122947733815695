@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

//------------------------------------------------------------
//slide
//------------------------------------------------------------*/
.swiper-pagination-bullet {
	background-color: #D9D9D9;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	cursor: pointer;
	opacity: 1;
	margin: 0 10px !important;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

	@include m.sp{
		width: 10px;
		height: 10px;
		margin: 0 8px !important;
	}
}

.swiper-pagination-bullet-active {
	background-color: v.$color3;
}

.swiper-pagination{
	bottom: 40px!important;

	@include m.sp{
		bottom: 20px!important;
	}
}

.swiper-button-next,
.swiper-button-prev {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	z-index: 9;
	cursor: pointer;

	@include m.sp {
		width: 40px;
		height: 40px;
	}

	&::before {
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: v.$color1;
		opacity: 0.3;
		position: absolute;
		top: 0;
		left: 0;
		transition: all .3s;
	}

	&::after {
		content: '';
		position: absolute;
		background: url(../img/common/icon_arrow1.svg) no-repeat center center;
		width: 18px;
		height: 18px;
		background-size: 18px 18px;
		z-index: 10;

		@include m.sp {
			width: 14px;
			height: 14px;
			background-size: 14px 14px!important;
		}
	}

	&:hover {
		&::before {
			background-color: v.$color3;
			opacity: 1;
		}
	}
}

.swiper-button-prev{
	&::after{
		transform: scale(-1);
	}
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
	&::before{
		opacity: 0.3;
		background-color: v.$color1;
	}
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
	left: 5%;

	@include m.min-w(1650px) {
		left: 7%;
	}
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
	right: 5%;

	@include m.min-w(1650px) {
		right: 7%;
	}
}

.swiper-slide {
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

// c-slider1
//------------------------------------------------------------*/
.c-slider1 {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 700px;
	width: 100%;

	@include m.sp{
		height: 350px;
	}

	.swiper-button-next,
	.swiper-button-prev {
		@include m.sp {
			display: none;
		}
	}

	.swiper-slide{
		@include m.mask;
	}

	&__ttl {
		font-size: 7rem;
		font-weight: 800;
		line-height: 1.2;
		color: #fff;
		text-align: center;
		-webkit-text-stroke: 1px rgba(0, 0, 0, 1);
		z-index: 2;
		margin-top: 100px;

		@include m.max-w(1200px) {
			font-size: 3.5rem;
			margin-top: 60px;
		}

		@include m.sp {
			font-size: 3.2rem;
			line-height: 3.7rem;
			margin-top: 40px;
		}
	}

	&__wrap {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}

/* c-slider2
------------------------------------------------------------*/
.c-slider2 {
	width: 100%;
	overflow: hidden;
	padding-bottom: 196px;

	@include m.sp{
		padding-bottom: 100px;
	}

	.swiper-container {
		position: relative;
	}

	.swiper-button-next,
	.swiper-button-prev {
		top: inherit;
		bottom: -110px;

		@include m.sp{
			bottom: -70px;
		}

		&::after {
			background: url(../img/common/icon_arrow_red.svg) no-repeat center;
			background-size: 18px 18px;
		}

		&::before{
			background-color: transparent;
			border: 1px solid v.$color3;
			opacity: 1;
		}

		@include m.hover{
			&::before{
				background-color: v.$color3;
			}

			&::after {
				background: url(../img/common/icon_arrow1.svg) no-repeat center;
				background-size: 18px 18px;

				@include m.sp{
					background-size: 14px 14px;
				}
			}
		}
	}

	.swiper-button-next {
		left: calc(50% + 43px);
		transform: translateX(-50%);

		@include m.sp{
			left: calc(50% + 35px);
		}
	}

	.swiper-button-prev {
		left: calc(50% - 43px);
		transform: translateX(-50%);

		@include m.sp{
			left: calc(50% - 35px);
		}
	}

	.c-list2{
		display: flex;
		grid-gap: 0;
		grid-template-columns: auto;
	}
}


