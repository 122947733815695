@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

.p-page {
  &__sc1 {
    padding: 60px 0 100px;
    @include m.sp {
      padding: 30px 0 50px;
    }
  }
}
