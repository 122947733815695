@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

.c-breadcrumb {
	background-color: v.$color5;

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		padding: 14px 0;
		box-sizing: border-box;

		@include m.sp {
			padding: 15px 0;
		}
	}

	&__item {
		padding: 0 10px;
		box-sizing: border-box;
		font-weight: 400;
		text-transform: uppercase;
		font-size: 1.6rem;

		@include m.sp{
			font-size: 1.4rem;
			line-height: 2.1rem;
		}
	}

	a {
		position: relative;

		&::before {
			position: absolute;
			content: '';
			background-color: #D9D9D9;
			right: -10px;
			top: 50%;
			width: 1px;
			height: 100%;
			transform: translateY(-50%);
		}

		@include m.hover{
			color: v.$color3;
		}
	}
}

