@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

//-------------------------------------------
// c-imgtxt1
//-------------------------------------------
.c-imgtxt1{
	display: flex;
	align-items: flex-end;

	@include m.sp{
		flex-wrap: wrap;
	}

	&__img{
		width: 42.3%;
		flex-shrink: 0;
		img{
			border-top-left-radius: 50px;
			border-top-right-radius: 50px;
			border-bottom-left-radius: 50px;

			@include m.sp{
				border-top-left-radius: 100px;
				border-top-right-radius: 100px;
				border-bottom-left-radius: 100px;
			}
		}

		@include m.sp{
			width: 100%;
			img{
				border-radius: 20px;
			}
		}
	}

	&__infor{
		flex-basis: 0;
		flex-grow: 1;
		background-color: #214082;
		border-top-left-radius: 50px;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		padding: 30px;
		color: #fff;
		position: relative;
		z-index: 1;

		@include m.min-w(1400px){
			border-top-left-radius: 100px;
			border-top-right-radius: 100px;
			border-bottom-right-radius: 100px;
			padding: 70px 100px 80px 100px;
		}

		@include m.sp{
			border-radius: 20px;
			padding: 20px;
			margin-top: 30px;
			flex-basis: auto;
			width: 100%;
		}

		&::after{
			position: absolute;
			content: '';
			width: 473px;
			height: 100%;
			bottom: 0;
			right: 0;
			background: url(../img/common/icon_mechanics_blue.svg) no-repeat bottom right;
			background-size: 100%;
			z-index: -1;

			@include m.sp{
				width: 200px;
			}
		}
	}

	&__ttl{
		display: flex;
		flex-direction: column;
	}

	&__sm{
		font-size: 1.8rem;
		line-height: 1.3;
		font-weight: 400;
		display: flex;
    	align-items: center;

		@include m.sp{
			font-size: 1.4rem;
		}

		img{
			margin-right: 13px;

			@include m.sp{
				zoom: 0.6;
				margin-right: 10px;
			}
		}
	}

	&__big{
		font-size: 5rem;
		font-weight: 800;
		line-height: 1.2;
		margin-top: 34px;

		@include m.sp{
			font-size: 2.4rem;
			margin-top: 15px;
		}
	}

	&__des{
		margin-top: 30px;
		text-align: justify;

		@include m.sp{
			margin-top: 20px;
		}
	}

	.c-btn1{
		margin-top: 50px;

		@include m.sp{
			margin: 50px auto 0;
		}

		@include m.sp{
			margin: 20px auto 0;
		}
	}
}

//-------------------------------------------
// c-imgtxt2
//-------------------------------------------
.c-imgtxt2{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	$root: &;

	&__img{
		width: 592px;
		flex-shrink: 0;
		margin-right: 98px;

		@include m.sp{
			width: 100%;
			margin-right: 0;
			margin-bottom: 20px;
		}

		img{
			border-top-left-radius: 100px;
			border-top-right-radius: 100px;
			border-bottom-left-radius: 100px;

			@include m.sp{
				border-radius: 20px;
				width: 100%;
			}
		}
	}

	&__infor{
		flex-basis: 0;
		flex-grow: 1;

		@include m.sp{
			flex-basis: auto;
			width: 100%;
		}
	}

	&__des{
		text-align: justify;

		+ #{$root}__des{
			margin-top: 30px;

			@include m.sp{
				margin-top: 20px;
			}
		}
	}
}

//-------------------------------------------
// c-imgtxt3
//-------------------------------------------
.c-imgtxt3{
	display: flex;
	flex-wrap: wrap;

	&__img{
		width: 66.1%;
		flex-shrink: 0;
		margin-right: 40px;

		@include m.sp{
			width: 100%;
			margin-bottom: 20px;
		}
	}

	&__infor{
		flex-basis: 0;
		flex-grow: 1;

		@include m.sp{
			flex-basis: auto;
			width: 100%;
		}
	}

	&__ttl{
		font-size: 5rem;
		line-height: 1.3;
		font-weight: 800;

		@include m.sp{
			font-size: 2.4rem;
		}
	}

	&__date{
		margin-top: 20px;
		line-height: 1;

		@include m.sp{
			font-size: 1.4rem;
			margin-top: 15px;
		}
	}

	&__txt{
		margin-top: 20px;

		@include m.sp{
			margin-top: 15px;
		}
	}

	.c-btn1{
		margin-top: 20px;

		@include m.sp{
			margin: 15px auto 0;
		}
	}
}
