@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

.c-form{
	$root: &;
	display: flex;
	flex-wrap: wrap;

	.wpcf7-spinner{
		display: block;
		margin: 0 auto;
	}

	&__des{
		width: 34%;
		flex-shrink: 0;

		@include m.sp{
			width: 100%;
		}
	}

	&__wrap{
		flex-basis: 0;
		flex-grow: 1;
		margin-left: 60px;

		@include m.sp{
			flex-basis: auto;
			width: 100%;
			margin-left: 0;
			margin-top: 30px;
		}
	}

	&__txt{
		font-size: 2.2rem;
		line-height: 3.6rem;

		@include m.sp{
			font-size: 1.6rem;
			line-height: 2.4rem;
		}
	}

	&__row{
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 60px;

		@include m.sp{
			column-gap: 20px;
		}

		input,
		textarea{
			background-color: #fff;
			border: 1px solid #C5C5C5;
			transition: all 0.3s;
			color: v.$color1;
			font-size: 1.8rem;
			line-height: 3.2rem;
			font-weight: 600;
			appearance: none;
			margin: 0;
			width: 100%;
			padding: 15px 25px;
			box-sizing: border-box;
			border-radius: 10px;

			@include m.sp{
				padding: 15px 10px;
				font-size: 1.6rem;
				line-height: 2.4rem;
			}

			&::placeholder{
				color: v.$color1;
				opacity: 0.5;
			}

			&:-ms-input-placeholde{
				color: v.$color1;
				opacity: 0.5;
			}

			&::-webkit-input-placeholder{
				color: v.$color1;
				opacity: 0.5;
			}

			&:focus{
				border: 1px solid v.$color3;
				outline: none;
			}
		}

		input{
			height: 60px;
			line-height: 60px;

			@include m.sp{
				height: 50px;
				line-height: 50px;
			}
		}

		textarea{
			overflow: auto;
			height: 120px;

			@include m.sp{
				height: 100px;
			}
		}


		& + #{$root}__row{
			margin-top: 20px;

			@include m.sp{
				margin-top: 15px;
			}
		}

		&.full-w{
			grid-template-columns: 1fr;
		}
	}

	&__btn{
		text-align: right;
	}

	&__submit{
		background-color: v.$color3;
		border: 1px solid v.$color3;
		font-size: 1.8rem;
		font-weight: 700;
		color: #fff;
		line-height: 1;
		cursor: pointer;
		margin: 0;
		padding: 0;
		height: 60px;
		width: 146px;
		margin-top: 42px;
		transition: all 0.3s;
		border-radius: 30px!important;
		background: v.$color3 url(../img/common/icon_arrow.svg) no-repeat center right 35px / 9px auto !important;

		@include m.sp{
			margin-top: 20px;
			height: 50px;
			font-size: 1.6rem;
		}

		&::after{
			position: relative;
			content: '';
			display: inline-block;
			width: 9px;
			height: 9px;
			background: url(../img/common/icon_arrow.svg) no-repeat center right;
			background-size: 9px 9px;
			margin-right: -9px;
			right: -8px;
		}

		@include m.hover{
			background-color: v.$color4;
		}
	}
}
