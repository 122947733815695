@use '../settings/mixin' as m;
@use '../settings/vars' as v;

//-------------------------------------------
// Layout
//-------------------------------------------

:root {
  --fw: 100vw;
  --fh: 100vh;
}

body {
  font-family: 'SFProDisplay', sans-serif;
  font-size: 1.8rem;
  line-height: 3.2rem;
  color: v.$color1;
  height: 100vh;
  opacity: 0;

  &.is-loader {
    opacity: 1;
  }

  @include m.sp {
    min-width: 320px;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  @include m.ios {
    // The hack for Safari
    // noinspection CssInvalidPropertyValue
    height: -webkit-fill-available;
  }
}

a {
  @include m.hover {
    opacity: 0.75;
  }
}

main {
  padding-top: 140px;
  @include m.sp {
    padding-top: 80px;
  }
}

.l-wrapper {
  position: relative;
  overflow: hidden;
}

.l-container {
  width: 100%;
  max-width: calc(#{v.$base-width} + 30px);
  padding: 0 15px;
  margin: 0 auto;
}

.l-content1 {
  width: 100%;
  max-width: 950px;
  padding: 0 15px;
  margin: 0 auto;
}

.l-layout1 {
  display: flex;
  justify-content: space-between;
  gap: 140px;

  @include m.sp {
    gap: 0;
    flex-direction: column;
  }

  &__side {
    flex-shrink: 0;
  }

  &__content {
    width: 100%;
  }
}

.l-sidebar {
  width: 458px;
  flex-shrink: 0;

  @include m.sp {
    width: 100%;
  }

  &.left {
    @include m.sp {
      margin-bottom: 40px;
    }
  }

  &.right {
    @include m.sp {
      margin-top: 40px;
    }
  }
}
