@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

//------------------------------------------------------------
//c-block1
//------------------------------------------------------------*/
.c-block1{
	padding: 100px 0 100px;
	box-sizing: border-box;

	@include m.sp{
		padding: 40px 0 40px;
	}

	.c-text1{
		max-width: 938px;
		width: 100%;
		margin: 32px auto 0;

		@include m.sp{
			margin: 25px auto 0;
		}
	}

	.c-list1{
		margin-top: 100px;

		@include m.sp{
			margin-top: 30px;
		}
	}
}

//------------------------------------------------------------
//c-block2
//------------------------------------------------------------*/
.c-block2{
	padding: 60px 0 98px;
	box-sizing: border-box;

	@include m.sp{
		padding: 40px 0 40px;
	}

	.c-title1{
		margin-bottom: 56px;

		@include m.sp{
			margin-bottom: 20px;
		}
	}

	.c-btn2{
		margin-top: 78px;

		@include m.sp{
			margin-top: 30px;
		}
	}
}

//------------------------------------------------------------
//c-block3
//------------------------------------------------------------*/
.c-block3{
	position: relative;

	&::before{
		position: absolute;
		content: '';
		background-color: v.$color5;
		width: 100%;
		height: 100%;
		right: 0;
		z-index: -1;
	}

	&__img{
		width: 84.7%;
		position: absolute;
		height: 100%;
		overflow: hidden;
		top: 0;
		left: 0;
		z-index: -1;

		@include m.sp{
			width: 100%;
			height: 100%;
		}

		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__inner{
		width: 100%;
		padding: 0 15px;
		margin: 0 auto;

		@include m.max-w(1920px){
			max-width: calc(v.$base-width + 30px);
		}

		@include m.sp{
			padding: 30px 15px;
		}
	}

	&__wrap{
		padding: 80px 0 100px 63px;
		box-sizing: border-box;
		max-width: 500px;
		width: 100%;
		margin-left: auto;
		background-color: v.$color5;

		@include m.sp{
			padding: 30px;
		}
	}

	&__ttl{
		display: flex;
		flex-direction: column;
	}

	&__sm{
		font-size: 1.8rem;
		line-height: 1.3;
		font-weight: 500;
		display: flex;
    	align-items: center;

		@include m.sp{
			font-size: 1.4rem;
		}

		img{
			margin-right: 13px;

			@include m.sp{
				zoom: 0.6;
				margin-right: 10px;
			}
		}
	}

	&__big{
		font-size: 5rem;
		font-weight: 800;
		line-height: 1.2;
		margin-top: 40px;

		@include m.sp{
			font-size: 2.4rem;
			margin-top: 15px;
		}
	}

	&__des{
		margin-top: 25px;

		@include m.sp{
			margin-top: 10px;
		}
	}

	.c-btn1{
		margin-top: 50px;
		box-shadow: none;

		@include m.max-w(1000px){
			margin: 50px auto 0;
		}

		@include m.sp{
			margin: 20px auto 0;
		}
	}
}

//------------------------------------------------------------
//c-block4
//------------------------------------------------------------*/
.c-block4{
	position: relative;

	&__img{
		@include m.sp{
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&__ct{
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		max-width: 700px;
		width: 100%;
		background-color: v.$color3;
		padding: 50px;
		box-sizing: border-box;
		color: #fff;

		@include m.sp{
			padding: 20px;
			width: 95%;
			margin: 0 auto;
			position: relative;
			top: initial;
			transform: none;
			margin-top: -20px;
		}
	}

	&__ttl{
		font-size: 5rem;
		font-weight: 800;
		line-height: 1.2;
		margin-bottom: 25px;

		@include m.sp{
			font-size: 2.4rem;
			margin-bottom: 15px;
		}
	}

	&__des{
		font-size: 2.2rem;
		line-height: 3.4rem;
		text-align: justify;
		font-weight: 500;

		@include m.sp{
			font-size: 1.6rem;
			line-height: 2.4rem;
		}
	}
}
