@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

.p-recruit {
  &__sc {
    padding: 100px 0 100px;
    box-sizing: border-box;

    @include m.sp {
      padding: 40px 0 40px;
    }

    .c-title1 {
      margin-bottom: 30px;

      @include m.sp {
        margin-bottom: 20px;
      }
    }

    .c-text1 {
      max-width: 938px;
      width: 100%;
      margin: 0 auto 60px;
      text-align: center;

      @include m.sp {
        margin-bottom: 30px;
      }
    }
  }
}

.c-list5 {
  $r: &;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  &.is-style2 {
    #{$r}__item {
      width: 100%;
    }
  }

  &__item {
    width: calc((100% - 40px) / 3);
    background-color: #F2F2F2;
    @include m.shadow;

    @include m.sp {
      width: 100%;
    }
  }

  &__link {
    display: flex;
  }

  &__img {
    width: 35%;
    position: relative;
    overflow: hidden;
    @include m.sp {
      width: 30%;
    }

    &:after {
      content: "";
      display: block;
      padding-top: calc(100 * 100% / 160);
    }

    img {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__infor {
    width: 65%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include m.sp {
      width: 70%;
    }
  }

  &__date {
    font-size: 1.4rem;
    font-weight: 400;
    @include m.sp {
      font-size: 1.2rem;
    }
  }

  &__ttl {
    font-size: 1.8rem;
    font-weight: bold;
    @include m.sp {
      font-size: 1.6rem;
    }
  }
}
