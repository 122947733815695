@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

// Todo: Plugin WP-PageNavi
//------------------------------------------------------------
.wp-pagenavi {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 100px;

	@include m.sp{
		margin-top: 30px;
	}

	a {
		cursor: pointer;
		display: block;
		color: #808080;
		@include m.hover {
			opacity: 1;
			text-decoration: none;
		}
	}

	.current, .page {
		position: relative;
		height: 60px;
		width: 60px;
		margin: 0 5px;
		font-size: 2rem;
		font-weight: 600;
		line-height: 1;
		text-decoration: none;
		transition: all 0.3s ease;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		flex-shrink: 0;
		border-radius: 50%;

		@include m.sp{
			width: 40px;
			height: 40px;
			font-size: 1.6rem;
		}
	}

	.page {
		color: v.$color1;
		border: 1px solid #808080;

		@include m.hover {
			color: #ffffff;
			background: v.$color3;
			border: 1px solid v.$color3;
		}
	}

	.current {
		color: #ffffff;
		background: v.$color3;
		border: 1px solid v.$color3;
	}

	.previouspostslink, .nextpostslink {
		position: relative;
		font-size: 0;
		margin: 0 5px;
		flex-shrink: 0;
		display: block;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		border: 1px solid #808080;
		cursor: pointer;

		@include m.sp{
			width: 40px;
			height: 40px;
		}

		&:after {
			content: "";
			width: 18px;
			height: 18px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);

			@include m.sp{
				width: 14px;
				height: 14px;
			}
		}

		@include m.hover{
			border-color: v.$color3;
			background-color: v.$color3;

			&:after{
				background: url(../img/common/icon_arrow1.svg) no-repeat center/100%;
			}
		}
	}

	span{
		&.nextpostslink,
		&.previouspostslink{
			display: none;
		}
	}

	.nextpostslink{
		&::after{
			background: url(../img/common/icon_arrow_right.svg) no-repeat center/100%;
		}
	}

	.previouspostslink{
		&::after {
			background: url(../img/common/icon_arrow_left.svg) no-repeat center/100%;
		}

		@include m.hover{
			&::after{
				background: url(../img/common/icon_arrow_left_w.svg) no-repeat center/100%;
			}
		}
	}

	.first, .last {
		display: none;
		white-space: nowrap;
		font-size: 1.4rem;
		color: v.$color1;
	}
}
