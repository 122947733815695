@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

.p-member{
	&__sc{
		padding: 100px 0 100px;
		box-sizing: border-box;

		@include m.sp{
			padding: 40px 0 40px;
		}

		.c-title1{
			margin-bottom: 30px;

			@include m.sp{
				margin-bottom: 20px;
			}
		}

		.c-text1{
			max-width: 938px;
			width: 100%;
			margin: 0 auto 60px;
			text-align: center;

			@include m.sp{
				margin-bottom: 30px;
			}
		}
	}
}
