@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

.c-footer {
  position: relative;
  $root: &;
  background-color: v.$color2;

  &__inner {
    @include m.sp {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }

  &__nav {
    display: grid;
    grid-template-columns: 1fr 165px 1fr 1fr;
    grid-gap: 2vw;
    padding: 80px 0 50px;
    box-sizing: border-box;

    @include m.sp {
      grid-template-columns: 1fr;
      grid-gap: 30px 0;
      padding: 40px 0 40px;
    }
  }

  &__ttl {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 900;
    color: #fff;
    display: block;
    margin-bottom: 30px;

    @include m.sp {
      font-size: 2rem;
      line-height: 2.4rem;
      margin-bottom: 15px;
    }
  }

  &__txt {
    color: #fff;
    opacity: 0.5;
    font-size: 1.8rem;
    line-height: 3.2rem;
    font-weight: 500;

    @include m.sp {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    + #{$root}__txt {
      margin-top: 15px;

      @include m.sp {
        margin-top: 10px;
      }
    }
  }

  &__address,
  &__mail,
  &__tel {
    position: relative;
    padding-left: 22px;

    &::before {
      position: absolute;
      content: '';
      top: 10px;
      left: 0;

      @include m.sp {
        top: 5px;
      }
    }
  }

  &__address {
    &::before {
      background: url(../img/common/icon_address_gr.svg) no-repeat center;
      width: 13px;
      height: 15px;
      background-size: 13px 15px;
    }
  }

  &__tel {
    margin-top: 50px !important;

    @include m.sp {
      margin-top: 30px !important;
    }

    &::before {
      background: url(../img/common/icon_tel_gr.svg) no-repeat center;
      width: 14px;
      height: 13px;
      background-size: 14px 13px;
    }
  }

  &__mail {
    &::before {
      top: 12px;
      background: url(../img/common/icon_mail_gr.svg) no-repeat center;
      width: 14px;
      height: 10px;
      background-size: 14px 10px;

      @include m.sp {
        top: 8px;
      }
    }
  }

  &__map {
    width: 100%;
    height: 210px;
    margin-top: 40px;

    @include m.sp {
      margin-top: 20px;
    }

    iframe {
      border-radius: 10px;
      width: 100%;
      height: 100%;
    }
  }

  &__logo {
    margin-right: 30px;

    @include m.sp {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      padding: 0 20px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #345CAF;
    }
  }

  &__img {
    @include m.sp {
      width: 163px;
      flex-shrink: 0;
    }
  }

  &__menu{
	a{
		color: #fff;
		opacity: 0.5;
		font-size: 1.8rem;
		line-height: 3.2rem;
		font-weight: 500;

		@include m.sp{
			font-size: 1.6rem;
			line-height: 2.4rem;
		}
	}

	li{
		& + li{
			margin-top: 15px;

			@include m.sp{
				margin-top: 10px;
			}
		}
	}
  }

  &__copyr {
    border-top: 1px solid #345CAF;
    // padding: 10px 0;
    // box-sizing: border-box;
    height: 80px;
    display: flex;
    align-items: center;

    @include m.sp {
      height: auto;
      padding: 10px 0 20px;
    }

    .l-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include m.sp {
        flex-direction: column;
        padding: 0;
      }
    }

    #{$root}__txt {
      @include m.sp {
        padding: 0 20px;
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__fb,
  &__yt {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.8rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 50px;
    box-sizing: border-box;

    @include m.sp {
      padding: 0;
      font-size: 1.6rem;
    }

    img {
      margin-right: 15px;

      @include m.sp {
        margin-right: 0;
      }
    }

    &::before {
      position: absolute;
      content: '';
      left: 0;
      height: 80px;
      width: 1px;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: #345CAF;

      @include m.sp {
        display: none;
      }
    }
  }

  &__yt {
    @include m.sp {
      margin-left: 40px;
    }

    &::after {
      position: absolute;
      content: '';
      right: 0;
      height: 80px;
      width: 1px;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: #345CAF;

      @include m.sp {
        display: none;
      }
    }
  }
}
