@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;

.p-project {
  &__sc {
    padding: 100px 0 100px;
    box-sizing: border-box;

    @include m.sp {
      padding: 40px 0 40px;
    }

    .c-title1 {
      margin-bottom: 30px;

      @include m.sp {
        margin-bottom: 20px;
      }
    }

    .c-text1 {
      max-width: 938px;
      width: 100%;
      margin: 0 auto 60px;
      text-align: center;

      @include m.sp {
        margin-bottom: 30px;
      }
    }

    .c-category {
      margin-bottom: 80px;
    }

    .c-select {
      margin-bottom: 40px;
    }
  }

  &__detail {
    &__sc {
      .c-title3 {
        margin-bottom: 50px;
        @include m.sp {
          margin-bottom: 30px;
        }
      }

      .c-sidebar {
        margin-bottom: 80px;

        @include m.sp {
          margin-bottom: 40px;
        }
      }

      .c-detail1 {
        margin-bottom: 30px;

		@include m.sp{
			margin-bottom: 10px;
		}
      }
    }
  }
}
