//-------------------------------------------
// Reset
//-------------------------------------------
* {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	box-sizing: border-box;

	&:before, &:after {
		box-sizing: border-box;
	}
}

html {
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
	margin: 0;
	padding: 0;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

body p {
	display: block;
	-webkit-margin-before: 0;
	-webkit-margin-after: 0;
	-webkit-margin-start: 0;
	-webkit-margin-end: 0;
}

video, iframe,
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
	display: block;
}

address, caption, cite, code, dfn, em, strong, th, var {
	font-style: normal;
}

body, table,
input, textarea, select, option,
h1, h2, h3, h4, h5, h6 {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
	font-size: 100%;
	text-align: left;
	font-weight: normal;
}

img, abbr, acronym, fieldset {
	border: 0;
}

button, input, textarea, select, option {
	font-family: inherit;
	line-height: 1.15;
}

input[type="submit"], button {
	border-radius: 0;
	-webkit-appearance: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

ol, ul {
	list-style: none;
}

li {
	list-style-type: none;
}

blockquote, q {
	quotes: none;
}

ins {
	text-decoration: none;
}

del {
	text-decoration: line-through;
}

img {
	vertical-align: top;
}

hr {
	box-sizing: content-box;
	overflow: visible;
	height: 0;
}

sub, sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -.25em;
}

sup {
	top: -.5em;
}

pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	-ms-overflow-style: scrollbar;
}

b, strong {
	font-weight: bolder;
}

a {
	color: inherit;
	text-decoration: none;
	transition: 0.3s;

	&:focus {
		outline: none;
	}

	img {
		transition: 0.3s;
	}
}

img {
	margin: 0;
	padding: 0;
	border: 0;
	max-width: 100%;
	vertical-align: middle;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}

:focus {
	outline: 0;
}
