@use '../settings/mixin' as m;
@use '../settings/vars' as v;
@use '../settings/path' as p;


//-------------------------------------------
// c-list1
//-------------------------------------------
.c-list1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  $root: &;

  @include m.sp {
    grid-template-columns: 1fr;
    grid-gap: 0;
    row-gap: 20px;
  }

  &__item {
    background-color: v.$color5;
    border-radius: 20px;
    position: relative;
    z-index: 1;

    &::before {
      pointer-events: none;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      border: 2px solid v.$color4;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      opacity: 0;
      transition: all 0.3s;
      z-index: -1;
    }

    @include m.hover {
      cursor: pointer;

      &::before {
        opacity: 1;
        z-index: 2;
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 58px 45px 28px;

    @include m.sp {
      padding: 30px;
    }
  }

  &__ttl {
    font-size: 2.2rem;
    font-weight: 800;
    line-height: 3.4rem;
    text-align: center;
    margin-top: 37px;
    text-transform: uppercase;

    @include m.sp {
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin-top: 20px;
    }
  }

  &__des {
    margin-top: 18px;
    text-align: center;

    @include m.sp {
      margin-top: 12px;
    }
  }
}

//-------------------------------------------
// c-list2
//-------------------------------------------
.c-list2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 21px;
  $root: &;

  @include m.sp {
    grid-template-columns: 1fr;
    grid-gap: 0;
    row-gap: 20px;
  }

  &__link {
    display: block;
    width: 100%;

    @include m.hover {
      opacity: 1;

      #{$root}__img {
        img {
          transform: scale(1.1);
          transition: 1s;
        }

        &::after {
          border-radius: 50%;
        }
      }
    }
  }

  &__infor {
    padding: 20px;
    box-sizing: border-box;

    @include m.sp {
      padding: 15px 15px 0;
    }
  }

  &__img {
    display: block;
    padding-top: calc(275 / 453 * 100%);
    position: relative;
    overflow: hidden;
    height: 0;
    transition: all 0.3s;

    &::after {
      position: absolute;
      content: '';
      transition: all 0.3s;
      width: 60px;
      height: 60px;
      bottom: 0;
      right: 0;
      background-color: v.$color3;

      @include m.sp {
        width: 40px;
        height: 40px;
      }
    }

    &::before {
      position: absolute;
      content: '';
      width: 60px;
      height: 60px;
      background: url(../img/common/icon_add.svg) no-repeat center;
      background-size: 19px 19px;
      bottom: 0;
      right: 0;
      z-index: 2;

      @include m.sp {
        width: 40px;
        height: 40px;
        background-size: 14px 14px !important;
      }
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: 1s;
      transform-origin: center;
      z-index: 0;
    }
  }

  &__txt {
    text-transform: uppercase;

    @include m.sp {
      font-size: 1.4rem;
      line-height: 2.1rem;
    }

    &.center {
      text-align: center;
    }
  }

  &__ttl {
    font-weight: 800;
    font-size: 2.2rem;
    line-height: 3.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 10px;

    @include m.sp {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }

    &.center {
      text-align: center;
    }
  }

  &.column1 {
    grid-template-columns: 1fr;
    grid-gap: 40px 0;

    @include m.sp {
      grid-gap: 20px 0;
    }
  }
}

//-------------------------------------------
// c-list3
//-------------------------------------------
.c-list3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 145px;
  $root: &;

  @include m.sp {
    grid-gap: 0;
    grid-template-columns: 1fr;
    row-gap: 30px;
  }

  &__item {
    p {
      text-align: justify;
      font-weight: 500;
    }
  }

  &__ttl {
    color: v.$color2;
    font-size: 2.6rem;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 20px;

    @include m.sp {
      margin-bottom: 15px;
      font-size: 2.2rem;
    }
  }

  &__txt {
    text-align: justify;
    font-weight: 500;

    + #{$root}__txt {
      margin-top: 15px;

      @include m.sp {
        margin-top: 10px;
      }

    }

    &.dot {
      position: relative;
      padding-left: 14px;

      @include m.sp {
        padding-left: 9px;
      }

      &::before {
        position: absolute;
        content: '';
        height: 4px;
        width: 4px;
        border-radius: 50%;
        top: 13px;
        left: 0;
        background: #000;

        @include m.sp {
          width: 3px;
          height: 3px;
          top: 10px;
        }
      }
    }
  }
}

//-------------------------------------------
// c-list4
//-------------------------------------------
.c-list4 {
  $root: &;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 21px;
  row-gap: 40px;

  @include m.sp {
    grid-template-columns: 1fr;
    row-gap: 20px;
    column-gap: 0;
  }

  &__link {
    display: block;
    width: 100%;

    @include m.hover {
      opacity: 1;

      #{$root}__img {
        img {
          transform: scale(1.1);
          transition: 1s;
        }

        &::after {
          border-radius: 50%;
        }
      }

      #{$root}__infor {
        transition: all 0.3s;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
    }
  }

  &__infor {
    padding: 20px 0 0;
    box-sizing: border-box;
    border: 1px solid #E7E7E7;
    border-top: none;

    @include m.sp {
      padding: 15px 0 0;
    }
  }

  &__img {
    display: block;
    padding-top: calc(275 / 453 * 100%);
    position: relative;
    overflow: hidden;
    height: 0;
    transition: all 0.3s;

    &::after {
      position: absolute;
      content: '';
      transition: all 0.3s;
      width: 60px;
      height: 60px;
      bottom: 0;
      right: 0;
      background-color: v.$color3;

      @include m.sp {
        width: 40px;
        height: 40px;
      }
    }

    &::before {
      position: absolute;
      content: '';
      width: 60px;
      height: 60px;
      background: url(../img/common/icon_add.svg) no-repeat center;
      background-size: 19px 19px;
      bottom: 0;
      right: 0;
      z-index: 2;

      @include m.sp {
        width: 40px;
        height: 40px;
        background-size: 14px 14px !important;
      }
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: 1s;
      transform-origin: center;
      z-index: 0;
    }
  }

  &__logo {
    border-left: 1px solid #E7E7E7;
    width: 143px;
    flex-shrink: 0;
  }

  &__ttl {
    font-weight: 800;
    font-size: 2.2rem;
    line-height: 3.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include m.sp {
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin-bottom: 15px;
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #E7E7E7;
    padding: 0 0 0 20px;
    box-sizing: border-box;

    @include m.sp {
      padding: 0 0 0 15px;
    }
  }

  &__txt {
    font-weight: 800;
  }

  &__name {
    display: block;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: 2.4rem;

    @include m.sp {
      font-size: 1.2rem;
    }
  }
}
